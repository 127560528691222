import React, { useState } from "react"
import SwipeableViews from "react-swipeable-views"
import { makeStyles } from "@material-ui/styles"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import MobileStepper from "@material-ui/core/MobileStepper"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"

const ImageOverlay = styled.div`
  background: rgba(29, 141, 190, 1);
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.lightText};

  > p {
    text-align: center;
  }
`

const getSliderImages = graphql`
  {
    images: allImageSharp(
      filter: {
        fluid: {
          originalName: {
            regex: "/gallery-image1|gallery-image2|gallery-image3|gallery-image4|galerie1|galerie2|galerie3|galerie4/"
          }
        }
      }
    ) {
      edges {
        node {
          fluid(maxWidth: 1500, maxHeight: 600, cropFocus: CENTER) {
            originalName
            ...GatsbyImageSharpFluid
            aspectRatio
          }
        }
      }
    }
  }
`
const useStyles = makeStyles({
  icon: {
    fontSize: 70,
  },
  iconsContainer: {
    backgroundColor: "#fff",
  },
})

function ImageGallery() {
  const classes = useStyles()

  const data = useStaticQuery(getSliderImages)
  const [activeStep, setActiveStep] = useState(0)
  const images = data.images.edges

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <Container maxWidth="xl" style={{ paddingTop: "100px" }}>
      <SwipeableViews enableMouseEvents index={activeStep}>
        {data.images.edges.map(({ node: image }) => {
          return (
            <div>
              <Img fluid={image.fluid} />
              {/* <ImageOverlay>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut
                </p>
              </ImageOverlay> */}
            </div>
          )
        })}
      </SwipeableViews>
      <MobileStepper
        className={classes.iconsContainer}
        activeStep={activeStep}
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {/* {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : ( */}
            <KeyboardArrowLeft className={classes.icon} />
            {/* )} */}
          </Button>
        }
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === (images.length - 1)}>
            {/* {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : ( */}
            <KeyboardArrowRight className={classes.icon} />
            {/* )} */}
          </Button>
        }
        position="static"
        steps={images.length - 1}
        variant=" "
      />
    </Container>
  )
}

export default ImageGallery
