import React from "react"
import Layout from "../components/layout"
import ImageGallery from "../components/image-gallery-slider"

function Gallery() {
  return (
    <Layout>
      <ImageGallery />
    </Layout>
  )
}

export default Gallery
